import React, {Component} from "react"
import styled from 'styled-components'
import classnames from 'classnames'
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import {graphql, StaticQuery} from 'gatsby'
import Layout from "components/Layout/Layout"
import Videos from "../components/Gallery/Videos"
import Photos from "../components/Gallery/Photos"
import VideoModal from "components/shared/VideoModal"
import {media} from "utils/Media"
import TopBar from "../components/shared/TopBar";
import snowFlakesXs from "../images/snowflakes-mobile.svg";
import snowFlakesXl from "../images/snowflakes-desktop.svg";

const scrollToElement = require('scroll-to-element')

const Content = styled.div`
  margin-top: 2rem;
  padding-bottom: 1rem;

  @media ${media.lg} {
    padding-bottom: 0;
  }
`
const Menu = styled.div`
  padding: 0;
  text-align: center;

  .nav-tabs {
    margin: 0 auto;
    padding: 0;
    display: flex;
    justify-content: center;
    border: none;
  }

  .nav-item {
    padding: 0;

    @media ${media.sm} {
      padding: 0 0.5rem;
    }
  }

  .nav-link {
    position: relative;
    border: none;
    color: #7BF6EF;
    cursor: pointer;
    transition: color 0.2s linear;

    h2 {
      color: #7BF6EF;
    }

    &.active {
      border: none;
      background: none;
      color: #003199;

      h2 {
        color: #003199;
      }
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: #003199;
      z-index: 2;
      opacity: 0;
      transition: opacity 0.2s linear;
    }

    &:hover, &:focus {
      border: none;

      h2 {
        color: #003199;
      }
    }

    &:hover::after, &:focus::after, &.active::after {
      opacity: 1;
    }
  }

`
const PageWrapper = styled.div`
  .buttonWrapper {
    justify-content: center;
    margin-bottom: 3rem;

    @media ${media.lg} {
      justify-content: left;
      margin-bottom: 0;
    }
  }

  h2 {
    font-family: ${props => props.theme.font.family.special};
    color: ${props => props.theme.colors.navy};
    margin: 0;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  h4 {
    font-size: 14px;
    font-family: ${props => props.theme.font.family.medium};
      font-weight: 500;

    @media ${media.md} {
      font-size: 18px;
    }
  }
`

const SnowFlakes = styled.div`
  background: url("${snowFlakesXs}") repeat -10% 140%;;
  background-size: 240%;

  @media ${media.sm} {
    background: url("${snowFlakesXl}") repeat 50% 70%;
    background-size: 290%;
  }

  @media ${media.md} {
    background: url("${snowFlakesXl}") repeat 50% 70%;
    background-size: 190%;
  }

  @media ${media.lg} {
    background: url("${snowFlakesXl}") repeat 50% 70%;
    background-size: 190%;
  }

  @media ${media.xl} {
    background: url("${snowFlakesXl}") no-repeat 50% 70%;
    background-size: 180%;
  }
`

const Query = () => (
  <StaticQuery
    query={graphql`
            query {
                ForegroundBottom: file(relativePath: { eq: "foreground-bottom.png" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
            }
		`}
    render={data => (
      <IndexPage data={data}/>
    )}
  />
)

class IndexPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: 'video'
        }

        this.toggle = this.toggle.bind(this)
    }

    componentDidMount() {

        if(typeof window !== "undefined" && window.location.hash) {
            let activeTab
            const hash = window.location.hash.replace("/", "")
            switch (hash) {
                case "#video":
                    activeTab = 'video'
                    break;
                case "#photography":
                    activeTab = 'photography'
                    break;

                default:
                    activeTab = 'video'
                    break;
            }
            this.setState({
                activeTab
            })
        }
    }

    toggle(tab) {
        this.setState({ activeTab: tab });
        let activeTab
        switch (tab) {
            case "video":
                activeTab = '#video'
                break;
            case "photography":
                activeTab = '#photography'
                break;

            default:
                activeTab = '#video'
                break;
        }
        window.location.hash = activeTab
    }
    
    toggleModal = (e, title, src) => {
        e.preventDefault();
        
        this.setState({
            src: src,
            title: title,
            modalOpen: !this.state.modalOpen
        });
    }

    onClick = () => {
        scrollToElement('#bottomOfIframe', {
            duration: 300
        });
    }

    render() {
        return (
            <Layout pageName="gallery" slug="gallery" title="Gallery">
                <SnowFlakes>
                <PageWrapper>
                    <TopBar pageName="gallery" title="Gallery"/>
                    <Content>
                        <Menu>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        className={classnames({active: this.state.activeTab === 'video'})}
                                        onClick={() => {
                                            this.toggle('video');
                                        }}
                                    >
                                        <h2>Video</h2>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({active: this.state.activeTab === 'photography'})}
                                        onClick={() => {
                                            this.toggle('photography');
                                        }}
                                    >
                                        <h2>Photography</h2>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Menu>
                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="video">
                                    <Videos/>
                            </TabPane>
                            <TabPane tabId="photography">
                                    <Photos/>
                            </TabPane>
                        </TabContent>
                    </Content>

                    <VideoModal toggleModal={this.toggleModal} title={this.state.title} src={this.state.src} modalOpen={this.state.modalOpen}/>
                </PageWrapper>
                </SnowFlakes>
            </Layout>
        )
    }
}

export default Query