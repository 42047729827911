import React, {Component} from "react"
import {StaticQuery, graphql} from "gatsby"
import {Container} from "reactstrap"
import styled from "styled-components"
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import {media} from "utils/Media"
import VideoButton from "../../components/shared/VideoButton"
import VideoModal from "../../components/shared/VideoModal"

const TopVideo = styled.div`
  position: relative;
  z-index: 2;
  text-align: left;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 0 0;

  @media ${media.lg} {
    z-index: 10;
  }

  @media ${media.xl} {
    padding: 3rem 0 0;
  }

  h2 {
    font-family: ${props => props.theme.font.family.special};
    color: ${props => props.theme.colors.navy};
    margin: 0 0 2rem;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;

  }

  h3 {
    font-family: ${props => props.theme.font.family.base};
    color: ${props => props.theme.colors.navy};
    margin: 1rem 0 0;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-decoration: underline;
  }

  .navy {
    color: ${props => props.theme.colors.navy};
  }

  a, .link {
    color: ${props => props.theme.colors.navy};
    font-weight: bold;
  }
`

const VideosBg = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  padding: 8vw 0;

  @media ${media.sm} {
    padding: 11vw 0;
  }

  @media ${media.md} {
    padding: 8vw 0;
  }

  @media ${media.lg} {
    padding: 11vw 0 15vw;
    z-index: 1;
    margin-top: -7vw; /* matches padding from other section */
    margin-bottom: -6rem;
  }

  @media ${media.xl} {
    padding: 9vw 0 8vw;
  }
`

const VideoWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;

  .video-item {
    margin: 1rem 0;
    width: 100%;
    display: flex;

    button, h4 {
      width: 50%;
      text-align: left;
    }

    h4 {
      padding-left: 1rem;
      margin: 0;
      display: flex;
      align-items: center;
      border-top: 1px solid #81E0FF;
      border-bottom: 1px solid #81E0FF;
    }

    @media ${media.sm} {

      display: block;
      width: calc(50% - 2rem);
      margin: 1rem 1rem;

      button, h4 {
        width: 100%;
        text-align: center;
      }

      h4 {
        padding-left: 0;
        border: none;
        display: block;
      }
    }

    @media ${media.lg} {
      margin: 1rem 1.5rem;
      width: calc(33% - 3rem);
    }
  }
`

const VideosContent = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 4;

  .btn--light-blue {
    max-width: 300px;
    font-size: 1rem;
  }

  .narrow {
    max-width: 900px;
  }

  h2 {
    font-family: ${props => props.theme.font.family.special};
    color: ${props => props.theme.colors.navy};
    margin: 0;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  h3 {
    font-family: ${props => props.theme.font.family.base};
    color: ${props => props.theme.colors.navy};
    margin: 1rem 0;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  a, .link {
    color: ${props => props.theme.colors.navy};
    font-weight: bold;
  }
`

const Query = (props) => (
  <StaticQuery
    query={graphql`
            query {
                videoPlaceholder1: file(relativePath: { eq: "Videos/FROZEN-JUL23-kaltura-video-poster-1522x856.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1470, quality: 100)
                    }
                }
                allVideosJson {
                    edges {
                        node {
                            title
                            subtitle
                            videoId
                            id
                            slug
                            posterImage {
                                childImageSharp {
                                    gatsbyImageData(width: 540, quality: 100)
                                }
                            }
                        }
                    }
                }
            }
		`}
    render={data => (
      <Videos id={props.id} data={data}/>
    )}
  />
)

class Videos extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      modalOpen: false,
      src: "",
      title: "",
    }
  }
  componentDidMount() {
    let hash = window.location.hash.split("?");
  
    if (hash[0] && hash[0].includes('#')) {
      this.props.data.allVideosJson.edges.map(({node}, i) => {
        if (node.slug === hash[0].replace("#", "")) {
          const that = this
          setTimeout(() => {
            that.toggleModal(node.title, node.videoId)
          }, 1000)
        }
      })
    }
  }
  componentWillUnmount() {
    this.setState({
      modalOpen: false,
      src: "",
      title: "",
      }
    )
  }
  
  toggleModal = ( title, src) => {
    this.setState({
      src: src,
      title: title,
      modalOpen: !this.state.modalOpen
    });
  }
  
  closeModal = () => {
    this.setState({
      modalOpen: false
    });
  }
  
  render() {
    const getVideos = this.props.data.allVideosJson.edges.map(({node}, i) => {
      console.log(node)
      return <VideoItem
        key={i}
        id={node.id ? node.id : `video-${i}`}
        video={node}
        toggleModal={this.toggleModal}
      />
    })
    return (
      <React.Fragment>
        <TopVideo>
          {/*<ScrollAnimation animateOnce={true} delay={0} animateIn="fadeIn">*/}
          
          <VideoButton
              onClick={() => this.toggleModal( "Watch Now! ‘Dangerous to Dream’ Music Video", "https://secure.disney.com/embed/5ffab6e9b5bf1ebd6b4f2a72?domain=www.disney.co.uk")}
            aria-label="Open video">
            <GatsbyImage image={this.props.data.videoPlaceholder1.childImageSharp.gatsbyImageData}
                         alt="Watch Now! ‘Dangerous to Dream’ Music Video"
                         title="Watch Now! ‘Dangerous to Dream’ Music Video"
                         className="img-fluid d-block"
            />
          </VideoButton>
          <h4 className="text-center mt-2">Watch Now! ‘Dangerous to Dream’ Music Video</h4>
          {/*</ScrollAnimation>*/}
        </TopVideo>
        <VideosBg id={this.props.id}>
          <VideosContent>
            <Container fluid={true}>
              <Container>
                {/*<ScrollAnimation animateOnce={true} delay={0} animateIn="fadeIn">*/}
                <VideoWrapper>
                  {getVideos}
                </VideoWrapper>
                {/*</ScrollAnimation>*/}
              </Container>
            </Container>
          </VideosContent>
          <VideoModal closeModal={this.closeModal} toggleModal={this.toggleModal} title={this.state.title} src={this.state.src}
                      modalOpen={this.state.modalOpen}/>
        </VideosBg>
      </React.Fragment>
    
    )
  }
}

export default Query

class VideoItem extends Component {
  
  render() {
    return (
      <div className="video-item" id={this.props.id}>
        <VideoButton onClick={() => this.props.toggleModal(this.props.video.title, this.props.video.videoId)}
                     aria-label="Open video">
          <GatsbyImage image={this.props.video.posterImage.childImageSharp.gatsbyImageData}
                       alt={this.props.video.title}
                       title={this.props.video.title}
                       className="img-fluid d-block"
          />
        </VideoButton>
        <h4 className="mt-sm-2">{this.props.video.title}</h4>
        {/*<h5>{this.props.video.subtitle}</h5>*/}
      </div>
    )
  }
}